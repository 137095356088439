<template>
  <div class="search-page">
    <top-bar />
    <page-title title="Поиск" description="Что-то ищете? Вам могут понравиться наши подборки" />

    <div class="container">
      <!--            <n-input icon-inner="search" placeholder="Поиск" :value.sync="v" @input="input" @change="change" />-->

      <div class="search-wrap">
        <n-icon icon="search" />
        <input ref="input" v-model="value" type="text" @input="() => onInput()">
      </div>

      <div style="position:relative;">
        <n-loader :loading="($var('load'))" />
        <c-infinity-scroll :more="(page) => load(page)" :active="!$var('load') && !done">
          <div v-if="goods.length" class="wrap">
            <c-product-card v-for="item in goods" :key="item.id" :product="item" />
          </div>
          <template v-else-if="!$var('load')">
            <div v-if="empty" class="page-message">Ничего не найдено</div>
            <div v-else class="page-message">Начните искать</div>
          </template>
        </c-infinity-scroll>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  data() {
    return {
      empty: false,
      value: '',
      goods: [],
      done: false,
    }
  },
  computed: {
    onInput() {
      return $n.debounce(this.load, 500)
    },
  },
  mounted() {
    this.$refs.input.focus()
  },
  methods: {
    load(page = 1) {
      if (this.value.length < 2) {
        this.empty = false
        this.goods = []
        return
      }
      this.$var('load', true)
      $api.app.search(this.value).page(page).then((response) => {
        if (page === 1) {
          this.goods = response.data.content
        } else {
          this.goods = [ ...this.goods, ...response.data.content, ]
        }
        this.empty = !this.goods.length
        const pg = response.data.pagination
        this.done = Math.ceil(pg.total / pg.size) <= pg.page
      }).finally(()=>{
        this.$var('load', false)
      })
    },
    loadOld() {
      this.$var('load', true)
      $api.app.search(this.value).then((response) => {
        this.goods = response.data.content
      }).finally(()=>{
        this.$var('load', false)
      })
    },
    loadNew() {
      return $api.app.search(this.value)
    },
  },
}
</script>

<style scoped lang="scss">
.search-page {
  .wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 15px -5px -5px;

    &::v-deep {
      .c-product-card {
        padding: 5px;
      }
    }
  }

  .search-wrap{
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    height: 40px;
    padding: 0 20px;

    input{
      width: 100%;
      border: none;
      margin-left: 5px;
      &:focus{
        outline: none;
      }
    }
  }
}
</style>
